<template>
  <table>
    <thead>
      <tr>
        <th>ページ名</th>
        <th>パス</th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="page in pages" :key="page.path">
        <th>
          <router-link :to="page.path">
            {{ page.meta.title }}
          </router-link>
        </th>
        <td>
          <router-link :to="page.path">
            {{ page.path }}
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  props: {
    pages: {
      type: Array,
      default: undefined
    }
  }
});
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  font-size: 11px;
  line-height: 1.5;
}

th,
td {
  padding-top: 2px;
  padding-bottom: 2px;
}

th {
  padding-right: 1em;
  width: 45%;
}

td {
  width: 55%;
}
</style>
