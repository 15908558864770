var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", [
    _vm._m(0),
    _c(
      "tbody",
      _vm._l(_vm.pages, function (page) {
        return _c("tr", { key: page.path }, [
          _c(
            "th",
            [
              _c("router-link", { attrs: { to: page.path } }, [
                _vm._v(" " + _vm._s(page.meta.title) + " "),
              ]),
            ],
            1
          ),
          _c(
            "td",
            [
              _c("router-link", { attrs: { to: page.path } }, [
                _vm._v(" " + _vm._s(page.path) + " "),
              ]),
            ],
            1
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [_c("th", [_vm._v("ページ名")]), _c("th", [_vm._v("パス")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }